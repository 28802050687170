import './styles/styles.css';
import Header from './siteComponents/header';

function App() {
  return (
    <>
      <Header />
    </>
  );
}

export default App;
